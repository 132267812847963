import service from '@/utils/request.js'
// 导入签名计算
import { encrypt } from '@/utils/encrypt'

/************************************
 * 账号组
 ************************************/


 /**
  * 
  * @param {账号列表} data 
  */
export function account_list(data={}) {
  return service.request({
    url: '/admin/account/list',
    method: 'get',
    params:data
  })
}


 /**
  * 
  * @param {添加账号} data 
  */
 export function account_save(data={}) {
    return service.request({
      url: '/admin/account/save',
      method: 'post',
      data
    })
  }


   /**
  * 
  * @param {删除套餐} data 
  */
export function account_delete(data={}) {
    return service.request({
      url: '/admin/account/delete',
      method: 'post',
      data
    })
  }

/**
 * 编辑套餐
 */
export function account_update(data={}) {
  return service.request({
    url: '/admin/account/update',
    method: 'post',
    data
  })
}


 /**
  * 
  * @param {角色列表} data 
  */
 export function account_role(data={}) {
    return service.request({
      url: '/admin/account/role',
      method: 'get',
      params:data
    })
}



 /**
  * 
  * @param {品牌列表} data 
  */
 export function account_brand(data={}) {
    return service.request({
      url: '/admin/account/brand',
      method: 'get',
      params:data
    })
}
  